import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import classNames from "classnames";
import * as React from "react";

export interface Props {
  clarification?: string | JSX.Element;
  className?: string;
  small?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
}

const ClarificationIcon: React.FC<Props> = ({
  clarification,
  className,
  small,
  placement
}: Props): JSX.Element | null => {
  if (!clarification) return null;

  return (
    <Tooltip
      title={clarification}
      className={className}
      placement={placement || "left"}
      classes={{
        tooltip:
          "bg-white paragraph font-normal shadow-default !p-[8px] rounded-4px border border-gray-200 text-left"
      }}
      enterTouchDelay={0}
    >
      <InfoOutlinedIcon
        className={classNames(
          small ? "w-[20px] h-[20px]" : "w-[24px] h-[24px]",
          "text-navy-900 fill-current"
        )}
      />
    </Tooltip>
  );
};

export default ClarificationIcon;
