import classNames from "classnames";
import * as React from "react";

const ErrorPlaceholder: React.FC<{
  text?: string;
  className?: string;
}> = ({ text = "Oops, something went wrong...", className }) => {
  return (
    <div
      className={classNames(
        "h-full flex justify-center w-full items-center text-gray-600",
        className
      )}
    >
      {text}
    </div>
  );
};

export default ErrorPlaceholder;
