import { Method } from "axios";

export const VALIDATION_RESPONSE_ERROR = "responseError";

export const REST_METHODS: { [key: string]: Method } = {
  post: "POST",
  patch: "PATCH",
  get: "GET",
  put: "PUT",
  delete: "DELETE"
};

export const NETWORK_CODES = {
  unauthorized: 401
};

export enum REQUEST_STATE {
  init,
  loading,
  done,
  error
}
